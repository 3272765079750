/*!
	* Om Swami - Global Stylesheet (c) 2022 <bahaasamir.me>
*/

/* Normalize */
* {-webkit-font-smoothing: subpixel-antialiased; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;font-kerning:auto;}.html{-webkit-text-size-adjust:none;-webkit-font-smoothing:subpixel-antialiased;-webkit-font-smoothing:antialiased}i{font-style:normal;}a,body,div,footer,h1,h2,h3,h4,h5,h6,header,html,img,li,nav,p,span,textarea,ul{margin:0;padding:0;border:0;outline: 0;}li,ul{list-style:none;}a{color:inherit;text-decoration:none;}a,img,picture,svg,video{display:block;}iframe, svg{width:100%;max-width: 100%;}html{-ms-text-size-adjust:100%;-webkit-overflow-scrolling: touch;-webkit-tap-highlight-color:transparent;}*, :after, :before {-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
/* Flickity */
.flickity-enabled{position:relative}.flickity-enabled:focus{outline:0}.flickity-viewport{position:relative;width: 100%;height:100%;}.flickity-slider{position:absolute;width:100%;will-change: transform;}.flickity-enabled.is-draggable{-webkit-tap-highlight-color:transparent;tap-highlight-color:transparent;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.flickity-prev-next-button{position:absolute;top:50%;width:44px;height:44px;border:none;border-radius:50%;background:#fff;background:hsla(0,0%,100%,.75);-webkit-transform:translateY(-50%);transform:translateY(-50%)}.flickity-prev-next-button:hover{background:#fff}.flickity-prev-next-button:focus{outline:0;box-shadow:0 0 0 5px #09f}.flickity-prev-next-button:active{opacity:.6}.flickity-prev-next-button.previous{left:10px}.flickity-prev-next-button.next{right:10px}.flickity-rtl .flickity-prev-next-button.previous{left:auto;right:10px}.flickity-rtl .flickity-prev-next-button.next{right:auto;left:10px}.flickity-prev-next-button:disabled{opacity:.3;}.flickity-prev-next-res_button svg{position:absolute;left:20%;top:20%;width:60%;height:60%}.flickity-prev-next-button .arrow{fill:#333}.flickity-page-dots{position:absolute;width:100%;bottom:-25px;padding:0;margin:0;list-style:none;text-align:center;line-height:1}.flickity-rtl .flickity-page-dots{direction:rtl}.flickity-page-dots .dot{display:inline-block;width:10px;height:10px;margin: 0 4px;background:#333;border-radius:50%;opacity:.25;}flickity-page-dots .dot.is-selected{opacity:1}

/* Fonts */
@font-face {
	font-family: 'Dahlia';
	src: url('../static/fonts/Dahlia-MediumCondensed.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mondia';
	src: url('../static/fonts/Mondia.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'MondiaThin';
	src: url('../static/fonts/MondiaThin.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'MilitaryScribe';
	src: url('../static/fonts/MilitaryScribe.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/* Basic */
body,
html {
	width: 100%;
	height: 100%;
}

body,
input,
textarea {
	font-family: 'Mondia';
	font-size: 20px;
	line-height: 1;
	font-kerning: none;
	text-rendering: optimizeSpeed;
	color: #373636;
}

body.hidden {
	overflow: hidden;
}

body.wait,
body.wait a {
	cursor: wait;
}

body:not(.wait) a,
body:not(.wait) .menu_button,
body:not(.wait) .site_button,
body:not(.wait) .equalizer {
	cursor: pointer;
}

/* Assists */
.SplitClass,
.header_side,
.sub_nav {
	-webkit-text-rendering: optimizeSpeed;
	text-rendering: optimizeSpeed;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	will-change: transform;
}

.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 20px;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	z-index: 2999;
	pointer-events: none;
}

.spinner:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border: 2px solid rgba(255, 255, 255, 0.3);
	border-bottom-color: #fff;
}

.spinner:not(.pause):before {
	-webkit-animation: rotateSpinner 300ms linear infinite;
	animation: rotateSpinner 300ms linear infinite;
}

@-webkit-keyframes rotateSpinner {
	to {
	-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
	}
}

@keyframes rotateSpinner {
	to {
	-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
	}
}

.zero-font > span {
	font-size: 0;
	position: absolute;
}

.full_bg {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.full_page {
	height: calc(var(--vh, 1vh) * 100);
}

/* Typography */
.alt_font {
    font-family: 'MilitaryScribe';
}

h1, h2, h3, h4, h5, h6, header h5 {
	font-family: 'Dahlia';
	font-weight: normal;
	font-style: normal;
	line-height: 80%;
}

h1 {
	font-size: 17.7vw;
}

h2 {
	font-size: 12vw;
	letter-spacing: -0.2vw;
}

h3 {
	font-size: 9.79vw;
	letter-spacing: -0.15vw;
}

h5 {
	font-family: 'Mondia';
	font-size: 5.2vw;
	letter-spacing: -0.2vw;
}

h6 {
	font-size: 3.1vw;
	/*letter-spacing: -0.15vw;*/
}

.alt_h1 {
	font-size: 3.6vw;
	letter-spacing: -0.04vw;
}

.alt_h2 {
	font-size: 3vw;
	letter-spacing: -0.13vw;
}

.alt_h3 {
	font-size: 2vw;
	letter-spacing: -0.1vw;
}

p,
.medium,
.large,
.small {
	line-height: 117%;
}

.thin {
	font-family: 'MondiaThin';
}

p {
	font-size: 1.4vw;
	
}

.small {
	font-size: 1.2vw;
	letter-spacing: -0.01vw;
}

.medium {
	font-size: 2vw;
}

.large {
	font-size: 2.6vw;
}

.micro {
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 1px;
	font-weight: 700;
}

.uppercase {
	text-transform: uppercase;
}

a {
	color: inherit;
}

/* Transitions */
body:not(.progress) .menu_lines i,
body:not(.progress) .menu_label span,
body:not(.progress) .bar,
body.isDesktop:not(.progress) .menu_items.ready li .menu_item,
body.isDesktop:not(.progress) .menu_items.ready li ._ele,
body:not(.progress) .cd_inner,
body:not(.progress) .cd_link_inner  {
	transition: all 500ms cubic-bezier(0.22, 0.61, 0.36, 1);
	-webkit-transition: all 500ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

body.isDesktop:not(.progress) .sb_inner {
	transition: all 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
	-webkit-transition: all 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

body:not(.progress) header,
body:not(.progress) .sub_nav a {
	transition: color 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
	-webkit-transition: color 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

/* Global */
main {
	position: relative;
	overflow: hidden;
}

.flex {
	display: flex;
}

.flex.space-between {
	justify-content: space-between;
}

.flex.align-items-center {
	align-items: center;
}


/* Header */
header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 699;
}

header.loaded {
	z-index: 2999;
}

.header_set {
	justify-content: space-between;
	position: absolute;
	width: 100%;
	padding: 40px 50px;
}

.menu_button {
	position: relative;
	color: #EBE5DE;
}

.site_button {
	width: 134px;
	display: inline-block;
	position: relative;
	z-index: 99;
}

.site_button:not(.menu_button):not(.submit) {
	width: 144px;
}

.menu_layer {
	z-index: -99;
	opacity: 0.4;
	height: calc(100% - 2px);
	background-size: cover;
	background-position: center;
}

svg.fill {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: -199;
}

header.active,
header.light {
	color: #EBE5DE;
}

.main_logo {
	padding: 5px 0;
}

.main_logo svg {
	width: 86px;
}

.menu_wrap {
	position: fixed;
	width: 100%;
	visibility: hidden;
}

.sub_nav {
	font-size: 17px;
	position: absolute;
	top: 48px;
	left: 35vw;
	z-index: 1999;
	visibility: hidden;
}

.sub_nav svg {
	width: 30px;
}

.sub_nav a {
	padding: 10px 20px;
	padding-bottom: 15px;
}

.sub_nav li:hover > a,
.sub_nav li a.active {
	color: rgb(235 229 222 / 70%);
}

.button_label {
	text-transform: uppercase;
	position: relative;
	text-align: center;
}

.menu_label span:last-child {
	opacity: 0;
	transform: translateY(15px);
	display: block;
}

.sb_inner {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
}

.sb_inner svg {
	height: 100%;
}

body.isDesktop:not(.progress):not(.wait) .site_button:hover .sb_inner,
body.isDesktop:not(.progress):not(.wait) .site_button.hover .sb_inner,
body.isDesktop:not(.progress):not(.wait) .site_button.active .sb_inner {
	transform: scaleX(1.15);
}

.button_label_set {
	justify-content: center;
	z-index: 99;
}

.menu_lines i {
	position: relative;
	display: block;
	height: 2px;
}

.menu_lines i svg {
	width: auto;
}

.menu_lines i:nth-child(3) {
	margin-top: 1px;
}

.menu_lines {
	margin-left: 4px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

header.opened .fill path {
	fill: #272929;
}

header.opened .menu_lines i:nth-child(2) {
	opacity: 0;
}

header.opened .menu_lines i:nth-child(1) {
	transform: rotate(-45deg) translate(-1px, 2px);
}

header.opened .menu_lines i:nth-child(3) {
	transform: rotate(45deg) translate(-2px, -2px);
}

header.opened .menu_label span:last-child {
	opacity: 1;
	transform: translateY(0);
}

header.opened .menu_label span:first-child {
	opacity: 0;
	transform: translateY(-15px);
}

.cd_inner {
	width: 264px;
	height: 137px;
	position: absolute;
	left: 20px;
	top: 100%;
	z-index: 2999;
	justify-content: center;
	visibility: hidden;
	transform: translateY(10px);
}

.cd_inner i {
	background-size: cover;
	background-position: center;
}

.cd_text {
	flex-direction: column;
}

.cd_link {
	font-size: 15px;
	letter-spacing: 0.5px;
	overflow: hidden;
	padding: 3px 24px;
}

.cd_link_inner {
	display: flex;
	white-space: nowrap;
	align-items: center;
}

.cd_link a {
	padding: 0;
}

.cd_link span {
	color: #B1B1B1;
}

.cd_svg {
	left: -1px;
	pointer-events: none;
	top: -1px;
	right: -1px;
	z-index: 99;
}

.cd_svg svg {
	width: 266px;
}

.header_side {
	position: relative;
	z-index: 199;
}

.menu_items {
	z-index: 99;
}

.menu_items li {
	width: 25%;
	min-width: 460px;
	min-height: 635px;
	text-align: center;
	padding: 100px 0;
	padding-bottom: 190px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.menu_items li > span {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.isDesktop .menu_items.hover ._ele {
	opacity: 0.2 !important;
}

.isDesktop .menu_items.hover .menu_item {
	opacity: 0.4 !important;
}

.isDesktop .menu_items.hover li.hover ._ele,
.isDesktop .menu_items.hover li.hover .menu_item {
	opacity: 1 !important;
}

.isDesktop .menu_items.hover .menu_item_count {
	transform: translateY(50px) !important;
}

.isDesktop .menu_items.hover li.hover .menu_item_count {
	transform: translateY(0) !important;
}

.menu_item {
	position: relative;
	width: 36.27vh;
	height: 38vh;
	min-width: 235px;
	min-height: 220px;
	margin-bottom: 30px;
}

.menu_items li p {
	font-size: 33px;
	letter-spacing: -1px;
}

.menu_items li h5 {
	font-size: 80px;
	letter-spacing: -1px;
}

.menu_item > i {
	background-size: cover;
	background-position: center;
}

.menu_item_count {
	position: absolute;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.menu_item_count span {
	font-size: 30px;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	border: 1px solid;
	border-radius: 50%;
}

.menu_item_line {
	width: 3px;
	height: 84px;
	background-size: cover;
	background-position: center;
}

.equalizer {
	height: 23px;
	padding: 5px;
	margin-left: 30px;
	position: relative;
	display: flex;
	align-items: flex-end;
	padding: 5px;
}

.bar {
	width: 2px;
	height: 10px;
	position: relative;
	margin-left: 3px;
	display: block;
	background: currentColor;
	transform-origin: bottom;
	transform: scaleY(0.5);
	opacity: 0.5;
}

.equalizer.active .bar {
	animation: bar 0.4s 0s both alternate ease infinite;
	opacity: 1;
}

.equalizer:not(.active) .bar {
	transform: scaleY(0.5) !important;
}

.equalizer.active .bar-1 {
	animation-delay: 0.2s;
}

.equalizer.active .bar-2 {
	animation-delay: 0.4s;
}

.equalizer.active .bar-3 {
	animation-delay: 0.6s;
}

@keyframes bar {
	0% {
		transform: scaleY(0);
	}
	100% {
		transform: scaleY(1);
	}
}
@media (max-width: 768px){
	h2 {
		font-size: 20vw;
		letter-spacing: -0.4vw;
	}
	h3 {
		font-size: 22vw;
	}
	.ju_head h3 {
		font-size: 22vw !important;
		letter-spacing: -0.4vw !important;
	}
	h6,
	.alt_h2 {
		font-size: 7vw;
	}
	.ju_head .alt_h2 {
		font-size: 7vw !important;
	}
	h5 {
		font-size: 8vw;
	}
	p {
		font-size: 3.4vw;
	}
	.alt_h3 {
		font-size: 4.5vw;
		letter-spacing: -.25vw;
	}
	.small {
		font-size: 2.7vw;
	}
	.ju_text .small {
		font-size: 2.7vw !important;
	}
	.medium {
		font-size: 5vw;
	}
	.large {
		font-size: 2.6vw;
	}

	.alt_h1 {
		font-size: 7vw;
	}
	.header_set {
		padding: 15px 25px;
	}
	.equalizer {
		margin-left: 20px;
	}
	.menu_items li {
		width: 100%;
		min-width: auto;
		padding-top: 0;
		padding-bottom: 100px;
	}
	.menu_item {
		height: 71.8125vw;
		width: 62vw;
	}
	.sub_nav {
		width: 100%;
		left: auto;
		bottom: 20px;
		top: auto;
		padding: 0 5px;
	}
	.sub_nav ul {
		justify-content: flex-start;
	}
	.sub_nav li:last-child {
		margin-left: auto
	}
	.cd_inner {
		top: auto;
		bottom: 100%;
		transform: translate(-10px);
		left: auto;
		right: 15px;
	}
}

@media (max-width: 500px){
	body {
		font-size: 17px;
	}
	h2 {
		font-size: 18vw;
		letter-spacing: -.2vw;
	}
	h3 {
		font-size: 26vw;
	}
	.ju_head h3 {
		font-size: 26vw !important;
	}
	h5 {
		font-size: 16vw;
	}
	.alt_h1 {
		font-size: 8vw;
	}
	.alt_h2,
	h6 {
		font-size: 7vw;
	}
	p {
		font-size: 4.4vw;
	}
	.ju_head .alt_h2 {
		font-size: 8vw !important;
	}
	.alt_h3 {
		font-size: 7vw;
		letter-spacing: -.3vw;
	}
	.small {
		font-size: 3.7vw;
	}
	.ju_text .small {
		font-size: 4.7vw !important;
		letter-spacing: -0.1vw !important;
	}
	.menu_item {
		width: 80vw;
		height: 91.8125vw;
	}
	.menu_items li {
		min-height: 775px;
	}
	.menu_items li p {
		font-size: 30px;
		margin-bottom: 5px;
	}
	.menu_items li h5 {
		font-size: 70px;
	}
	.site_button.menu_button {
		width: 114px;
	}
}